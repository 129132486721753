import React from 'react';

export const PlatformPortu: React.FC = () => {
    return (
        <svg
            width="134"
            height="45"
            viewBox="0 0 134 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'portu'}
        >
            <path
                d="M21.6516 20.9471L41.6614 32.4998L21.6516 44.0526L1.6416 32.4996L21.6516 20.9471ZM21.6516 19.9993L0 32.4996L21.6516 45.0002L43.303 32.4996L21.6516 19.9993Z"
                fill="currentColor"
            />
            <path d="M43.3031 12.5004L21.6515 25.0012L0 12.5004L21.6515 0L43.3031 12.5004Z" fill="currentColor" />
            <path d="M21.6515 25.0008V35.0002L0 22.4994V12.5L21.6515 25.0008Z" fill="currentColor" />
            <path d="M21.6515 25.0008V35.0002L43.303 22.4994V12.5L21.6515 25.0008Z" fill="currentColor" />

            <path
                d="M69.2044 19.9793C69.2044 24.4287 66.2227 27.8996 62.2167 27.8996C61.4325 27.9365 60.6504 27.7914 59.9316 27.4757C59.2128 27.1601 58.5767 26.6824 58.0732 26.0801V32.5007L53.3026 35.2547V12.5025H57.9203V14.1231C58.4202 13.4638 59.0718 12.9347 59.8197 12.5807C60.5676 12.2267 61.3899 12.0582 62.2168 12.0896C66.2227 12.0896 69.2044 15.5758 69.2044 19.9793ZM57.8742 19.9945C57.8742 21.9823 59.1892 23.4349 61.0699 23.4349C62.9966 23.4349 64.2808 21.9517 64.2808 19.9945C64.2808 18.0374 62.9966 16.5543 61.0699 16.5543C59.1892 16.5543 57.8742 18.0067 57.8742 19.9945Z"
                fill="currentColor"
            />
            <path
                d="M87.2605 19.9947C87.2605 24.5359 83.8049 27.8998 79.1107 27.8998C74.4165 27.8998 70.9766 24.5359 70.9766 19.9947C70.9766 15.4536 74.417 12.0898 79.1107 12.0898C83.8045 12.0898 87.2605 15.4534 87.2605 19.9947ZM75.8694 19.9947C75.8694 22.0131 77.2453 23.4351 79.1107 23.4351C80.9917 23.4351 82.3677 22.0131 82.3677 19.9947C82.3677 17.9764 80.9917 16.5545 79.1107 16.5545C77.2453 16.5543 75.8694 17.9763 75.8694 19.9947Z"
                fill="currentColor"
            />
            <path
                d="M100.195 12.3192L99.7517 17.0897C98.9778 16.8301 98.1674 16.6959 97.3511 16.6922C95.6387 16.6922 94.6144 17.5179 94.6144 19.3985V27.5023H89.8286V12.5027H94.4153V14.2305C95.2869 12.7626 96.6631 12.0899 98.5133 12.0899C99.0818 12.0881 99.6477 12.1653 100.195 12.3192Z"
                fill="currentColor"
            />
            <path
                d="M109.914 16.7377V21.126C109.914 22.8537 110.831 23.4348 112.039 23.4348C112.941 23.4348 113.889 22.9455 114.638 22.5021L116.29 26.2326C114.749 27.3602 112.878 27.9463 110.969 27.8993C106.932 27.8993 105.128 25.4836 105.128 21.7373V16.7377H102.33V12.5023H105.128V7.76465L109.914 5.00195V12.5023H115.035V16.7377H109.914Z"
                fill="currentColor"
            />
            <path
                d="M125.856 27.8991C121.85 27.8991 118.608 25.7737 118.608 21.0644V12.502H123.394V20.6515C123.394 22.5476 124.434 23.4344 125.856 23.4344C127.278 23.4344 128.318 22.5476 128.318 20.6515V12.502H133.088V21.0797C133.088 25.7737 129.847 27.8991 125.856 27.8991Z"
                fill="currentColor"
            />
        </svg>
    );
};
