import React from 'react';

export const PlatformBinance: React.FC = () => {
    return (
        <svg
            width="225"
            height="45"
            viewBox="0 0 225 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'binance'}
        >
            <path
                d="M13.615 18.9094L22.3541 10.1707L31.0977 18.914L36.1827 13.829L22.3541 0L8.53003 13.8244L13.615 18.9094Z"
                fill="currentColor"
            />
            <path
                d="M9.97577 22.499L4.89087 17.4141L-0.194279 22.4992L4.89062 27.5841L9.97577 22.499Z"
                fill="currentColor"
            />
            <path
                d="M13.5668 26.0902L22.3059 34.8289L31.0492 26.0859L36.137 31.1681L36.1345 31.1709L22.3059 44.9996L8.48147 31.1755L8.47437 31.1684L13.5668 26.0902Z"
                fill="currentColor"
            />
            <path
                d="M39.7212 27.5871L44.8064 22.502L39.7215 17.4171L34.6364 22.5022L39.7212 27.5871Z"
                fill="currentColor"
            />
            <path
                d="M27.4636 22.4969H27.4658L22.3058 17.3369L18.4925 21.1502H18.4921L18.0543 21.5884L17.1504 22.4923L17.1433 22.4994L17.1504 22.5068L22.3058 27.6622L27.4658 22.5022L27.4682 22.4994L27.4636 22.4969Z"
                fill="currentColor"
            />
            <path
                d="M52.7336 10.9043H63.7932C66.5381 10.9043 68.6138 11.6087 70.0205 13.0176C71.1092 14.1088 71.6533 15.4611 71.6533 17.0744V17.1426C71.6533 17.8243 71.5691 18.4267 71.3996 18.9492C71.2304 19.4724 71.0043 19.9433 70.7218 20.3638C70.4399 20.7846 70.1122 21.1539 69.7397 21.4716C69.3669 21.7901 68.9664 22.063 68.5374 22.2898C69.9171 22.813 71.0033 23.5228 71.7951 24.4205C72.5867 25.3187 72.9829 26.563 72.9829 28.1535V28.2214C72.9829 29.3125 72.7733 30.2668 72.3535 31.0854C71.9338 31.9032 71.3324 32.5852 70.5494 33.1308C69.7664 33.6764 68.8249 34.0851 67.7245 34.3581C66.6241 34.6307 65.4047 34.7668 64.0665 34.7668H52.7336V10.9043ZM62.6875 20.5514C63.8469 20.5514 64.7674 20.3531 65.4488 19.9551C66.1305 19.5573 66.4713 18.9151 66.4713 18.029V17.9608C66.4713 17.1657 66.1756 16.558 65.5849 16.1371C64.9938 15.7167 64.1415 15.5063 63.0284 15.5063H57.8467V20.5514H62.6875ZM64.0854 30.1652C65.2444 30.1652 66.1529 29.9555 66.8125 29.5347C67.4715 29.1142 67.8013 28.4606 67.8013 27.5742V27.5063C67.8013 26.7109 67.4942 26.0803 66.8808 25.614C66.2669 25.1488 65.2785 24.9156 63.9148 24.9156H57.8467V30.1655L64.0854 30.1652Z"
                fill="currentColor"
            />
            <path d="M79.5696 10.9043H84.8198V34.7675H79.5696V10.9043Z" fill="currentColor" />
            <path
                d="M92.7715 10.9043H97.6116L108.793 25.5973V10.9043H113.975V34.7675H109.51L97.9528 19.5975V34.7675H92.7715V10.9043Z"
                fill="currentColor"
            />
            <path
                d="M129.87 10.7334H134.71L144.938 34.7669H139.449L137.267 29.4149H127.176L124.995 34.7669H119.642L129.87 10.7334ZM135.392 24.7781L132.221 17.04L129.052 24.7781H135.392Z"
                fill="currentColor"
            />
            <path
                d="M150.605 10.9043H155.446L166.627 25.5973V10.9043H171.809V34.7675H167.343L155.786 19.5975V34.7675H150.605V10.9043Z"
                fill="currentColor"
            />
            <path
                d="M190.703 35.1761C188.952 35.1761 187.328 34.8577 185.827 34.2218C184.327 33.586 183.032 32.7163 181.941 31.6138C180.85 30.5116 179.997 29.2104 179.385 27.7106C178.771 26.2103 178.464 24.6081 178.464 22.9035V22.8356C178.464 21.131 178.771 19.5349 179.385 18.046C179.998 16.5575 180.85 15.2506 181.941 14.1257C183.032 13.0008 184.339 12.1141 185.861 11.4665C187.383 10.8189 189.066 10.4951 190.907 10.4951C192.02 10.4951 193.037 10.5865 193.957 10.7677C194.878 10.9501 195.713 11.1996 196.463 11.5177C197.214 11.8361 197.906 12.2225 198.543 12.6767C199.178 13.1316 199.77 13.6313 200.315 14.1769L196.975 18.0289C196.042 17.1884 195.094 16.5287 194.128 16.0517C193.162 15.5748 192.077 15.3359 190.873 15.3359C189.872 15.3359 188.946 15.5293 188.095 15.9156C187.242 16.302 186.509 16.8358 185.895 17.5175C185.282 18.1992 184.805 18.9893 184.463 19.8867C184.123 20.7849 183.953 21.7449 183.953 22.7674V22.8353C183.953 23.8578 184.123 24.8242 184.463 25.7327C184.805 26.6422 185.275 27.4372 185.879 28.1189C186.48 28.8006 187.208 29.3409 188.06 29.7386C188.913 30.1367 189.85 30.335 190.873 30.335C192.237 30.335 193.389 30.0851 194.332 29.585C195.275 29.0857 196.213 28.4036 197.145 27.5396L200.486 30.9147C199.872 31.574 199.235 32.165 198.577 32.6871C197.918 33.2103 197.196 33.6589 196.412 34.0342C195.628 34.4088 194.769 34.6935 193.838 34.8861C192.906 35.0791 191.861 35.1761 190.703 35.1761Z"
                fill="currentColor"
            />
            <path
                d="M206.665 10.9043H224.63V15.5749H211.847V20.4157H223.096V25.0859H211.847V30.0973H224.801V34.7675H206.665V10.9043Z"
                fill="currentColor"
            />
        </svg>
    );
};
