import React from 'react';
import { ImportTypeEnum } from '@app/common';
import { PlatformDegiro } from '../platforms/degiro';
import { PlatformEtoro } from '../platforms/etoro';
import { PlatformXtb } from '../platforms/xtb';
import { PlatformTrading212 } from '../platforms/trading212';
import { PlatformInteractiveBrokers } from '../platforms/interactive-brokers';
import { PlatformMonery } from '../platforms/monery';
import { PlatformCoinbase } from '../platforms/coinbase';
import { PlatformAnycoin } from '../platforms/anycoin';
import { PlatformPortu } from '../platforms/portu';
import { PlatformBinance } from '../platforms/binance';
import { PlatformFio } from '../platforms/fio';
import { PlatformSnowball } from '../platforms/snowball';
import { PlatformYahooFinance } from '../platforms/yahoo-finance';
import { PlatformCoinmate } from '../platforms/coinmate';
import { PlatformPatria } from '../platforms/patria';
import { PlatformLynx } from '../platforms/lynx';

type PlatformType = {
    type: ImportTypeEnum;
};

const logoComponents = {
    [ImportTypeEnum.Degiro]: PlatformDegiro,
    [ImportTypeEnum.Etoro]: PlatformEtoro,
    [ImportTypeEnum.Xtb]: PlatformXtb,
    [ImportTypeEnum.Trading212]: PlatformTrading212,
    [ImportTypeEnum.InteractiveBrokers]: PlatformInteractiveBrokers,
    [ImportTypeEnum.Monery]: PlatformMonery,
    [ImportTypeEnum.Coinbase]: PlatformCoinbase,
    [ImportTypeEnum.Anycoin]: PlatformAnycoin,
    [ImportTypeEnum.Portu]: PlatformPortu,
    [ImportTypeEnum.Binance]: PlatformBinance,
    [ImportTypeEnum.Fio]: PlatformFio,
    [ImportTypeEnum.Snowball]: PlatformSnowball,
    [ImportTypeEnum.YahooFinance]: PlatformYahooFinance,
    [ImportTypeEnum.Coinmate]: PlatformCoinmate,
    [ImportTypeEnum.Patria]: PlatformPatria,
    [ImportTypeEnum.Lynx]: PlatformLynx,
};

export const Platform: React.FC<PlatformType> = ({ type }: PlatformType) => {
    const LogoComponent = logoComponents[type];

    if (!LogoComponent) {
        return null;
    }

    return <LogoComponent />;
};
