import React from 'react';

export const PlatformSnowball: React.FC = () => {
    return (
        <svg
            width="203"
            height="40"
            viewBox="0 0 203 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'snowball'}
        >
            <path
                d="M2.62199 25.0491C4.12826 25.8301 6.47136 26.6112 8.87024 26.6112C11.4365 26.6112 12.8312 25.5512 12.8312 23.9334C12.8312 22.3713 11.6596 21.4787 8.64709 20.4187C4.51878 18.9682 1.78517 16.6809 1.78517 13.0547C1.78517 8.81481 5.3556 5.5791 11.2133 5.5791C14.0027 5.5791 16.0669 6.19277 17.5732 6.80644L16.3458 11.3253C15.3417 10.8232 13.6122 10.1537 11.1576 10.1537C8.70288 10.1537 7.53133 11.2695 7.53133 12.5526C7.53133 14.1147 8.92603 14.8399 12.1059 16.0672C16.5132 17.6851 18.5216 19.9724 18.5216 23.4871C18.5216 27.6712 15.3417 31.2416 8.47973 31.2416C5.63454 31.2416 2.84514 30.4606 1.45044 29.6795L2.62199 25.0491Z"
                fill="currentColor"
            />
            <path
                d="M24.8813 30.7953V6.02539H31.4085L36.541 15.1188C37.9915 17.7409 39.4978 20.8092 40.6136 23.5986H40.7251C40.3346 20.3071 40.2231 16.9598 40.2231 13.222V6.02539H45.3556V30.8511H39.5536L34.2537 21.2555C32.8032 18.5777 31.1854 15.3978 29.9581 12.4968L29.8465 12.5526C30.0138 15.8441 30.0696 19.3029 30.0696 23.3755V30.8511L24.8813 30.7953Z"
                fill="currentColor"
            />
            <path
                d="M74.0307 18.1314C74.0307 26.2765 69.1214 31.1858 61.8689 31.1858C54.5049 31.1858 50.2092 25.607 50.2092 18.5777C50.1534 11.1579 54.8954 5.5791 62.2036 5.5791C69.8466 5.5791 74.0307 11.3253 74.0307 18.1314ZM56.067 18.4661C56.067 23.3197 58.3543 26.7228 62.0921 26.7228C65.8857 26.7228 68.0614 23.0965 68.0614 18.2988C68.0614 13.8357 65.9414 10.0421 62.0363 10.0421C58.2985 10.0421 56.067 13.6684 56.067 18.4661Z"
                fill="currentColor"
            />
            <path
                d="M83.5146 30.7954L77.6011 5.96973H83.6262L85.523 16.1789C86.0809 19.1357 86.5829 22.3714 86.9735 24.8819H87.0292C87.4198 22.204 88.0334 19.1915 88.6471 16.1789L90.767 6.02551H96.7364L98.7447 16.4579C99.3026 19.3588 99.6931 22.0367 100.084 24.7703H100.139C100.53 22.0367 101.032 19.0799 101.59 16.1789L103.598 6.02551H109.289L102.873 30.8512H96.7921L94.6722 20.1957C94.1701 17.6852 93.7796 15.3979 93.5007 12.5527H93.4449C92.9986 15.3421 92.608 17.6852 91.9944 20.1957L89.6513 30.8512L83.5146 30.7954Z"
                fill="currentColor"
            />
            <path
                d="M113.975 6.35951C115.425 6.08057 118.438 5.85742 121.227 5.85742C124.63 5.85742 126.75 6.19215 128.536 7.25212C130.265 8.20052 131.492 9.87416 131.492 12.1057C131.492 14.3372 130.209 16.4014 127.42 17.4055V17.4613C130.265 18.2424 132.329 20.3623 132.329 23.598C132.329 25.8295 131.325 27.6147 129.763 28.8421C127.978 30.2926 124.965 31.0736 120 31.0736C117.266 31.0736 115.202 30.9062 113.919 30.6831L113.975 6.35951ZM119.498 15.8435H121.339C124.296 15.8435 125.858 14.6161 125.858 12.7751C125.858 10.8783 124.407 9.92995 121.897 9.92995C120.67 9.92995 120 9.98573 119.498 10.0973V15.8435ZM119.498 26.8337C120.056 26.8895 120.725 26.8895 121.674 26.8895C124.24 26.8895 126.471 25.8853 126.471 23.3191C126.471 20.8086 124.24 19.8044 121.451 19.8044H119.554V26.8337H119.498Z"
                fill="currentColor"
            />
            <path
                d="M143.264 24.4356L141.478 30.7954H135.677L143.264 5.96973H150.628L158.326 30.7954H152.301L150.405 24.4356H143.264ZM149.568 20.2515L148.006 15.0074C147.559 13.5569 147.113 11.7159 146.778 10.2096H146.723C146.332 11.6601 145.997 13.5569 145.607 15.0074L144.156 20.2515H149.568Z"
                fill="currentColor"
            />
            <path d="M164.24 6.02539H169.875V26.1091H179.749V30.7953H164.24V6.02539Z" fill="currentColor" />
            <path d="M185.607 6.02539H191.241V26.1091H201.116V30.7953H185.607V6.02539Z" fill="currentColor" />
        </svg>
    );
};
