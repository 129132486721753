import React from 'react';

export const PlatformInteractiveBrokers: React.FC = () => {
    return (
        <svg
            width="110"
            height="16"
            viewBox="0 0 110 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'interactive-brokers'}
        >
            <path d="M13.4381 15.6899H11.6758V7.17188H13.4381V15.6899Z" fill="currentColor" />
            <path
                d="M20.0713 9.74185C20.4874 10.0845 20.6832 10.6475 20.6832 11.4307V15.6898H18.9942V11.8713C18.9942 11.5287 18.9453 11.2839 18.8719 11.1126C18.7005 10.7943 18.4068 10.623 17.9418 10.623C17.3788 10.623 17.0116 10.8678 16.7913 11.3328C16.6934 11.5776 16.62 11.8958 16.62 12.2875V15.7142H14.98V9.39912H16.571V10.3292C16.7913 10.0111 16.9871 9.76631 17.1585 9.61944C17.5011 9.37466 17.9172 9.22778 18.4558 9.22778C19.1166 9.22778 19.6551 9.39912 20.0713 9.74185Z"
                fill="currentColor"
            />
            <path
                d="M21.4907 10.6231V9.44815H22.3719V7.68579H24.0119V9.44815H25.0399V10.6231H24.0119V13.9519C24.0119 14.2212 24.0364 14.368 24.1098 14.4415C24.1833 14.515 24.379 14.5394 24.7217 14.5394C24.7707 14.5394 24.8196 14.5394 24.8931 14.5394C24.942 14.5394 25.0154 14.5394 25.0644 14.5394V15.7633L24.2322 15.7878C23.4489 15.8123 22.9104 15.6899 22.6412 15.3717C22.4454 15.1759 22.3719 14.8821 22.3719 14.4904V10.6231L21.4907 10.6231Z"
                fill="currentColor"
            />
            <path
                d="M30.107 9.52134C30.5475 9.71714 30.9147 10.0354 31.2084 10.4515C31.4777 10.8186 31.6246 11.2592 31.7225 11.7487C31.7714 12.0424 31.7959 12.4586 31.7714 12.9971H27.1697C27.1942 13.6335 27.4145 14.074 27.8305 14.3188C28.0753 14.4657 28.3935 14.5636 28.7362 14.5636C29.1034 14.5636 29.4216 14.4657 29.6419 14.2699C29.7642 14.172 29.8867 14.0251 29.9846 13.8293H31.6735C31.6246 14.1964 31.4287 14.5881 31.0616 14.9797C30.4986 15.5916 29.7153 15.9098 28.6873 15.9098C27.8551 15.9098 27.0963 15.6406 26.4598 15.1266C25.8235 14.6126 25.5052 13.7559 25.5052 12.6054C25.5052 11.504 25.7989 10.6717 26.3864 10.0843C26.9739 9.49683 27.7082 9.20312 28.6383 9.20312C29.1768 9.22764 29.6664 9.32549 30.107 9.52134ZM27.6347 10.941C27.39 11.1857 27.2431 11.504 27.1942 11.9201H30.0335C30.009 11.4795 29.8622 11.1613 29.593 10.9165C29.3237 10.6962 29.0055 10.5738 28.6139 10.5738C28.1977 10.5738 27.8795 10.6962 27.6347 10.941Z"
                fill="currentColor"
            />
            <path
                d="M36.2262 9.25208C36.2507 9.25208 36.2997 9.25208 36.3731 9.25208V10.941C36.2752 10.941 36.1773 10.9165 36.1038 10.9165C36.0304 10.9165 35.957 10.9165 35.908 10.9165C35.2471 10.9165 34.8066 11.1367 34.5618 11.5529C34.4394 11.7976 34.3659 12.1648 34.3659 12.6788V15.6895H32.7015V9.3989H34.268V10.5004C34.5128 10.0843 34.7331 9.79051 34.9289 9.64363C35.2472 9.37439 35.6388 9.25203 36.1283 9.25203C36.1772 9.22751 36.2018 9.22757 36.2262 9.25208Z"
                fill="currentColor"
            />
            <path
                d="M39.8242 11.8712C40.1425 11.8223 40.3627 11.7733 40.4851 11.7244C40.7299 11.6265 40.8523 11.4796 40.8523 11.2593C40.8523 10.99 40.7544 10.8187 40.583 10.7208C40.4117 10.6229 40.1425 10.5739 39.7753 10.5739C39.3837 10.5739 39.0899 10.6718 38.9431 10.8677C38.8207 11.0145 38.7472 11.2104 38.6983 11.4551H37.1073C37.1318 10.8921 37.3031 10.4516 37.5723 10.0844C38.013 9.52144 38.7717 9.2522 39.8242 9.2522C40.5096 9.2522 41.146 9.39902 41.6845 9.66831C42.223 9.93755 42.4923 10.4516 42.4923 11.2104V14.0986C42.4923 14.2944 42.4923 14.5392 42.4923 14.833C42.4923 15.0532 42.5412 15.2001 42.5902 15.2735C42.6391 15.347 42.737 15.4204 42.835 15.4693V15.7141H41.0481C40.9992 15.5918 40.9746 15.4693 40.9502 15.347C40.9257 15.2246 40.9257 15.1022 40.9013 14.9553C40.6809 15.2001 40.4117 15.4204 40.118 15.5918C39.7509 15.7876 39.3592 15.9099 38.8941 15.9099C38.3312 15.9099 37.8416 15.7386 37.4745 15.4204C37.1073 15.1022 36.9115 14.6371 36.9115 14.0252C36.9115 13.242 37.2052 12.679 37.8172 12.3363C38.1598 12.1405 38.6249 12.0181 39.2858 11.9202L39.8242 11.8712ZM40.8278 12.63C40.7299 12.7035 40.6075 12.7524 40.5096 12.8014C40.4117 12.8503 40.2648 12.8748 40.069 12.9237L39.7019 12.9972C39.3592 13.0707 39.0899 13.1441 38.9431 13.2175C38.6738 13.3644 38.5514 13.6091 38.5514 13.9273C38.5514 14.2211 38.6249 14.4169 38.7962 14.5393C38.9431 14.6616 39.1389 14.7351 39.3837 14.7351C39.7508 14.7351 40.069 14.6372 40.3872 14.4169C40.6809 14.1965 40.8523 13.8294 40.8523 13.2664V12.63H40.8278Z"
                fill="currentColor"
            />
            <path
                d="M47.6569 11.651C47.6324 11.4063 47.559 11.2105 47.4121 11.0146C47.2163 10.7699 46.9471 10.623 46.5555 10.623C45.9925 10.623 45.6253 10.8922 45.4051 11.4552C45.3071 11.7489 45.2337 12.1406 45.2337 12.6301C45.2337 13.0952 45.2826 13.4623 45.4051 13.7561C45.6009 14.2701 45.968 14.5393 46.531 14.5393C46.9226 14.5393 47.1919 14.4414 47.3632 14.2212C47.5346 14.0008 47.6325 13.7316 47.6569 13.4134H49.3458C49.2969 13.9274 49.1256 14.3924 48.8074 14.8331C48.2933 15.5429 47.5346 15.91 46.5065 15.91C45.4784 15.91 44.7442 15.6163 44.2546 15.0044C43.7651 14.3924 43.5448 13.6092 43.5448 12.6546C43.5448 11.5776 43.814 10.7209 44.3281 10.1335C44.8665 9.52154 45.5764 9.22778 46.531 9.22778C47.3142 9.22778 47.9751 9.39912 48.4647 9.76631C48.9542 10.1335 49.2724 10.7454 49.3703 11.651H47.6569Z"
                fill="currentColor"
            />
            <path
                d="M49.8842 10.6231V9.44815H50.7653V7.68579H52.405V9.44815H53.4333V10.6231H52.405V13.9519C52.405 14.2212 52.4297 14.368 52.503 14.4415C52.5769 14.515 52.7722 14.5394 53.1153 14.5394C53.164 14.5394 53.2133 14.5394 53.2866 14.5394C53.3353 14.5394 53.4087 14.5394 53.4579 14.5394V15.7633L52.6502 15.7878C51.8668 15.8123 51.3283 15.6899 51.0591 15.3717C50.8633 15.1759 50.7898 14.8821 50.7898 14.4904V10.6231L49.8842 10.6231Z"
                fill="currentColor"
            />
            <path
                d="M56.1995 8.64058H54.5349V7.12305H56.1995V8.64058ZM54.5103 9.39937H56.1749V15.7145H54.5103V9.39937Z"
                fill="currentColor"
            />
            <path
                d="M61.5991 9.34253H63.3617L61.0852 15.6576H59.3472L57.0953 9.34253H58.9559L60.2775 13.9932L61.5991 9.34253Z"
                fill="currentColor"
            />
            <path
                d="M68.1445 9.52134C68.585 9.71714 68.9522 10.0354 69.2456 10.4515C69.5148 10.8186 69.662 11.2592 69.7599 11.7487C69.8087 12.0424 69.8333 12.4586 69.8087 12.9971H65.207C65.2316 13.6335 65.4516 14.074 65.868 14.3188C66.1127 14.4657 66.4311 14.5636 66.7737 14.5636C67.1409 14.5636 67.4588 14.4657 67.6794 14.2699C67.8014 14.172 67.924 14.0251 68.0219 13.8293H69.7107C69.662 14.1964 69.4661 14.5881 69.0989 14.9797C68.5358 15.5916 67.7527 15.9098 66.7245 15.9098C65.8927 15.9098 65.1337 15.6406 64.4972 15.1266C63.8608 14.6126 63.5428 13.7559 63.5428 12.6054C63.5428 11.504 63.8362 10.6717 64.4239 10.0843C65.0111 9.49683 65.7455 9.20312 66.6758 9.20312C67.2142 9.22764 67.7035 9.32549 68.1445 9.52134ZM65.6721 10.941C65.4275 11.1857 65.2803 11.504 65.2316 11.9201H68.0707C68.0466 11.4795 67.8994 11.1613 67.6301 10.9165C67.3609 10.6962 67.0429 10.5738 66.6511 10.5738C66.2352 10.5738 65.9168 10.6962 65.6721 10.941Z"
                fill="currentColor"
            />
            <path
                d="M70.8857 7.19629H74.557C75.5607 7.19629 76.2704 7.49004 76.6869 8.07749C76.9315 8.42017 77.054 8.83629 77.054 9.30132C77.054 9.83985 76.9069 10.2804 76.5889 10.6231C76.4417 10.7944 76.1971 10.9658 75.9032 11.1127C76.3438 11.284 76.6622 11.4553 76.8822 11.6756C77.274 12.0428 77.4453 12.5568 77.4453 13.2177C77.4453 13.7562 77.2741 14.2702 76.9315 14.7108C76.4176 15.3717 75.5853 15.7143 74.4591 15.7143H70.8364V7.19629H70.8857ZM74.1411 10.7944C74.6304 10.7944 75.0222 10.721 75.2914 10.5986C75.732 10.3783 75.9279 10.0112 75.9279 9.4482C75.9279 8.88521 75.7073 8.51807 75.2427 8.32227C74.9735 8.22437 74.6063 8.15093 74.0919 8.15093H72.0113V10.7944H74.1411ZM74.5324 14.7108C75.2427 14.7108 75.7566 14.515 76.0504 14.0988C76.2458 13.8296 76.3438 13.5359 76.3438 13.1687C76.3438 12.5568 76.0745 12.1407 75.512 11.8959C75.2181 11.7735 74.8263 11.7246 74.337 11.7246H72.0359V14.7108H74.5324Z"
                fill="currentColor"
            />
            <path
                d="M78.7181 9.49687H79.6971V10.5739C79.771 10.3536 79.9664 10.1088 80.2849 9.81509C80.6028 9.52139 80.97 9.37451 81.3864 9.37451C81.411 9.37451 81.4351 9.37451 81.4844 9.37451C81.5331 9.37451 81.6064 9.37451 81.729 9.39903V10.5005C81.6557 10.5005 81.6064 10.476 81.5577 10.476C81.5085 10.476 81.4598 10.476 81.3864 10.476C80.872 10.476 80.4561 10.6473 80.1869 10.99C79.893 11.3327 79.771 11.7243 79.771 12.1649V15.7385H78.7181V9.49687Z"
                fill="currentColor"
            />
            <path
                d="M87.065 10.1088C87.6035 10.6473 87.8974 11.4306 87.8974 12.4586C87.8974 13.4622 87.6522 14.2944 87.163 14.9308C86.6732 15.5917 85.9388 15.9099 84.9111 15.9099C84.0542 15.9099 83.3931 15.6161 82.9039 15.0531C82.4141 14.4657 82.1449 13.7069 82.1449 12.7278C82.1449 11.6753 82.4141 10.8676 82.9285 10.2312C83.4424 9.61924 84.1521 9.30103 85.0332 9.30103C85.8409 9.32554 86.5019 9.59478 87.065 10.1088ZM86.404 14.2454C86.6486 13.7314 86.7958 13.144 86.7958 12.5075C86.7958 11.9201 86.6978 11.455 86.5266 11.0879C86.2327 10.5249 85.7429 10.2312 85.0332 10.2312C84.3968 10.2312 83.9562 10.4759 83.6624 10.9655C83.369 11.455 83.2219 12.0425 83.2219 12.7278C83.2219 13.3887 83.369 13.9272 83.6624 14.3678C83.9562 14.8084 84.3968 15.0287 85.0332 15.0287C85.6942 15.0287 86.1594 14.7594 86.404 14.2454Z"
                fill="currentColor"
            />
            <path
                d="M89.0477 7.19629H90.0513V12.1407L92.7191 9.49718H94.0412L91.6668 11.798L94.1879 15.6899H92.8663L90.9078 12.5323L90.0513 13.3645V15.6899H89.0477V7.19629Z"
                fill="currentColor"
            />
            <path
                d="M98.5202 9.6685C98.9361 9.8643 99.2546 10.1335 99.45 10.4762C99.6459 10.7944 99.7931 11.1616 99.8664 11.5777C99.9397 11.8714 99.9643 12.312 99.9643 12.9484H95.4606C95.4847 13.5848 95.6319 14.0744 95.9011 14.466C96.1704 14.8576 96.6109 15.029 97.174 15.029C97.7125 15.029 98.153 14.8576 98.471 14.4905C98.6422 14.2702 98.7894 14.0499 98.8628 13.7562H99.891C99.8664 13.9765 99.7684 14.2457 99.6218 14.5149C99.4746 14.7842 99.3033 15.029 99.1074 15.2003C98.7894 15.5185 98.3976 15.7143 97.9325 15.8367C97.6879 15.9102 97.394 15.9346 97.076 15.9346C96.317 15.9346 95.656 15.6654 95.1175 15.1024C94.579 14.5394 94.3098 13.7562 94.3098 12.7526C94.3098 11.749 94.579 10.9413 95.1175 10.3293C95.656 9.71742 96.3663 9.39921 97.2227 9.39921C97.6879 9.35029 98.1038 9.4727 98.5202 9.6685ZM98.9115 12.1162C98.8628 11.6756 98.7648 11.3084 98.6181 11.0392C98.3243 10.5252 97.8591 10.2804 97.1981 10.2804C96.7335 10.2804 96.317 10.4518 95.9991 10.7944C95.6806 11.1371 95.5093 11.5777 95.4847 12.1162H98.9115Z"
                fill="currentColor"
            />
            <path
                d="M101.213 9.49687H102.192V10.5739C102.265 10.3536 102.461 10.1088 102.779 9.81509C103.097 9.52139 103.464 9.37451 103.881 9.37451C103.905 9.37451 103.93 9.37451 103.978 9.37451C104.027 9.37451 104.101 9.37451 104.223 9.39903V10.5005C104.15 10.5005 104.101 10.476 104.052 10.476C104.003 10.476 103.954 10.476 103.881 10.476C103.366 10.476 102.951 10.6473 102.681 10.99C102.387 11.3327 102.265 11.7243 102.265 12.1649V15.7385H101.213V9.49687Z"
                fill="currentColor"
            />
            <path
                d="M105.765 13.756C105.79 14.0986 105.888 14.3679 106.035 14.5637C106.304 14.9064 106.769 15.0777 107.43 15.0777C107.821 15.0777 108.164 15.0043 108.482 14.833C108.776 14.6616 108.923 14.4169 108.923 14.0497C108.923 13.7804 108.801 13.5846 108.556 13.4378C108.409 13.3643 108.091 13.2664 107.65 13.1441L106.818 12.9237C106.279 12.8014 105.888 12.6545 105.619 12.4832C105.154 12.1894 104.933 11.8223 104.933 11.3083C104.933 10.7208 105.154 10.2558 105.57 9.88857C105.986 9.52144 106.573 9.3501 107.308 9.3501C108.262 9.3501 108.948 9.61934 109.363 10.1823C109.633 10.525 109.755 10.9166 109.755 11.3083H108.776C108.752 11.0635 108.678 10.8677 108.532 10.6718C108.287 10.4026 107.871 10.2557 107.259 10.2557C106.867 10.2557 106.549 10.3292 106.353 10.476C106.157 10.6229 106.035 10.8187 106.035 11.0635C106.035 11.3327 106.182 11.553 106.451 11.6999C106.598 11.7978 106.842 11.8712 107.136 11.9446L107.846 12.116C108.605 12.2873 109.119 12.4832 109.388 12.6545C109.804 12.9237 110 13.3399 110 13.9028C110 14.4658 109.78 14.9309 109.363 15.3225C108.948 15.7141 108.287 15.9344 107.43 15.9344C106.5 15.9344 105.839 15.7141 105.447 15.298C105.056 14.8819 104.86 14.3679 104.811 13.7315H105.765V13.756Z"
                fill="currentColor"
            />
            <path d="M0 15.6899V8.05298L6.95148 15.6899H0Z" fill="currentColor" />
            <path
                d="M5.9723 11.9939C7.12133 11.9939 8.05282 11.0624 8.05282 9.91334C8.05282 8.76425 7.12133 7.83276 5.9723 7.83276C4.82322 7.83276 3.89172 8.76425 3.89172 9.91334C3.89172 11.0624 4.82322 11.9939 5.9723 11.9939Z"
                fill="currentColor"
            />
            <path d="M0 8.05294V15.6898L6.95148 0L0 8.05294Z" fill="currentColor" />
        </svg>
    );
};
