import React from 'react';

export const PlatformPatria: React.FC = () => {
    return (
        <svg
            width="174"
            height="33"
            viewBox="0 0 174 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'patria'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M166.634 32.2553L163.676 25.0565L160.495 17.1321L156.924 8.48236L152.682 18.8621L150.171 25.0565L147.214 32.2553H140.071L153.799 0H160.272L174 32.2553H166.634ZM127.124 0.167416H134.155V32.2553H127.124V0.167416ZM110.717 32.1995L103.853 21.9872H98.3284V32.1995H91.2412V0.223221H105.918C109.936 0.223221 113.061 1.28352 115.237 3.45991C117.023 5.30148 117.972 7.70109 117.972 10.7704V10.882C117.972 16.016 115.182 19.3085 111.108 20.8153L118.92 32.1995H110.717ZM110.885 11.161C110.885 8.09173 108.82 6.58499 105.304 6.58499H98.3284V15.737H105.471C108.931 15.737 110.885 13.9513 110.885 11.2168V11.161ZM75.3368 32.2553H68.3053V6.75241H58.5394V0.223221H85.0468V6.75241H75.3368V32.2553ZM52.2893 32.2553L49.3316 25.0565L46.1507 17.1321H46.0949L42.5792 8.48236L38.338 18.8621L35.8268 25.0565L32.8691 32.2553H25.7261L39.3983 0H45.9275L59.6555 32.2553H52.2893ZM12.3887 22.6568H7.03143V32.2553H0V0.223221H13.0584C20.7037 0.223221 25.3355 4.74343 25.3355 11.3284V11.3842C25.3355 18.8621 19.5876 22.6568 12.3887 22.6568ZM18.1924 11.44C18.1924 8.25914 16.016 6.58499 12.5003 6.58499H7.03143V16.3509H12.6119C16.1276 16.3509 18.1924 14.2861 18.1924 11.5516V11.44Z"
                fill="currentColor"
            />
            <path
                d="M157.035 22.3223L152.069 27.2889L157.035 32.2556L162.002 27.2889L157.035 22.3223ZM42.635 22.3223L37.6683 27.2889L42.635 32.2556L47.6016 27.2889L42.635 22.3223Z"
                fill="currentColor"
            />
        </svg>
    );
};
