import React from 'react';

export const PlatformYahooFinance: React.FC = () => {
    return (
        <svg
            width="163"
            height="45"
            viewBox="0 0 163 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'yahoo'}
        >
            <path
                d="M0 10.9636H9.65455L15.2591 25.3227L20.9455 10.9636H30.3545L16.2 45H6.75L10.6364 36L0 10.9636ZM40.1318 10.3909C32.8909 10.3909 28.3091 16.8955 28.3091 23.3591C28.3091 30.6409 33.3409 36.4091 40.0091 36.4091C45 36.4091 46.8818 33.3818 46.8818 33.3818V35.7545H55.3091V10.9636H46.8818V13.2136C46.8409 13.2136 44.7545 10.3909 40.1318 10.3909ZM41.9318 18.3682C45.2864 18.3682 47.0045 21.0273 47.0045 23.4C47.0045 25.9773 45.1636 28.5136 41.9318 28.5136C39.2727 28.5136 36.8591 26.3455 36.8591 23.5227C36.8591 20.6591 38.7818 18.3682 41.9318 18.3682ZM58.1727 35.7545V0H66.9682V13.2955C66.9682 13.2955 69.0545 10.3909 73.4318 10.3909C78.7909 10.3909 81.9409 14.4 81.9409 20.0864V35.7545H73.1864V22.2136C73.1864 20.2909 72.2864 18.4091 70.2 18.4091C68.0727 18.4091 66.9682 20.2909 66.9682 22.2136V35.7545H58.1727ZM96.9545 10.3909C88.65 10.3909 83.7 16.6909 83.7 23.4818C83.7 31.1727 89.6727 36.45 96.9955 36.45C104.073 36.45 110.25 31.4182 110.25 23.6045C110.25 15.0545 103.745 10.3909 96.9545 10.3909ZM97.0364 18.4091C99.9818 18.4091 101.986 20.8636 101.986 23.4409C101.986 25.65 100.105 28.3909 97.0364 28.3909C94.2136 28.3909 92.1273 26.1409 92.1273 23.4C92.1273 20.8227 93.8455 18.4091 97.0364 18.4091ZM124.895 10.3909C116.591 10.3909 111.641 16.6909 111.641 23.4818C111.641 31.1727 117.614 36.45 124.936 36.45C132.014 36.45 138.191 31.4182 138.191 23.6045C138.191 15.0545 131.727 10.3909 124.895 10.3909ZM124.977 18.4091C127.923 18.4091 129.927 20.8636 129.927 23.4409C129.927 25.65 128.045 28.3909 124.977 28.3909C122.155 28.3909 120.068 26.1409 120.068 23.4C120.068 20.8227 121.827 18.4091 124.977 18.4091ZM145.145 24.5864C148.377 24.5864 150.995 27.2045 150.995 30.4364C150.995 33.6682 148.377 36.2864 145.145 36.2864C141.914 36.2864 139.295 33.6682 139.295 30.4364C139.295 27.2045 141.914 24.5864 145.145 24.5864ZM152.918 22.4591H142.405L151.732 0H162.205L152.918 22.4591Z"
                fill="currentColor"
            />
        </svg>
    );
};
