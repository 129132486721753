import React from 'react';

export const PlatformEtoro: React.FC = () => {
    return (
        <svg
            width="56"
            height="18"
            viewBox="0 0 56 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'etoro'}
        >
            <path
                d="M55.345 6.46687C55.0232 5.26271 52.5234 2.46772 50.7329 0.229416C50.713 0.204607 50.5738 0 50.3857 0C50.1924 0 50.0611 0.188941 50.1249 0.337741C50.1906 0.492785 51.9437 4.10719 51.6173 5.30579C51.29 6.50339 49.6977 6.26978 49.4356 6.26978C49.3098 6.26978 49.3944 6.37385 49.5493 6.51287C50.4199 7.29544 51.0227 8.3893 51.0227 9.85111V10.9349C51.0227 10.9561 51.0408 10.9845 51.0759 10.9796C53.0643 10.7542 56.1596 9.51797 55.345 6.46687Z"
                fill="currentColor"
            />
            <path
                d="M47.4829 13.4828C47.4829 14.512 46.1014 15.1009 45.2015 15.1009C44.2393 15.1009 42.8777 14.512 42.8777 13.4828V9.85163C42.8777 8.82176 44.2393 8.33845 45.2015 8.33845C46.1014 8.33845 47.4829 8.82171 47.4829 9.85163V13.4828ZM45.2015 6.08911C43.0673 6.10968 40.577 7.35046 40.577 9.85167V13.656C40.577 16.1994 43.0674 17.3984 45.2015 17.4189C47.2943 17.3984 49.7844 16.1994 49.7844 13.656V9.85167C49.7844 7.35037 47.2943 6.10968 45.2015 6.08911Z"
                fill="currentColor"
            />
            <path
                d="M31.4055 13.4828C31.4055 14.512 30.0237 15.1009 29.1239 15.1009C28.1617 15.1009 26.8004 14.512 26.8004 13.4828V9.85163C26.8004 8.82176 28.1617 8.33845 29.1239 8.33845C30.0237 8.33845 31.4055 8.82171 31.4055 9.85163V13.4828ZM29.1239 6.08911C26.9903 6.10968 24.5 7.35046 24.5 9.85167V13.656C24.5 16.1994 26.9903 17.3984 29.1239 17.4189C31.2158 17.3984 33.7052 16.1994 33.7052 13.656V9.85167C33.7052 7.35037 31.2158 6.10968 29.1239 6.08911Z"
                fill="currentColor"
            />
            <path
                d="M5.92785 6.51287C6.08402 6.37288 6.16752 6.26876 6.04214 6.26876C5.77944 6.26876 4.18725 6.50275 3.86026 5.30547C3.531 4.10686 5.28582 0.492452 5.3524 0.338075C5.41573 0.187993 5.28356 0 5.09165 0C4.90265 0 4.764 0.204328 4.74389 0.229751C2.95297 2.46805 0.454817 5.26271 0.131709 6.46687C-0.682775 9.51741 2.46686 10.7529 4.45483 10.979C4.49122 10.9829 4.50742 10.9552 4.50742 10.9343V9.85079C4.50748 8.3893 5.05688 7.29443 5.92785 6.51287Z"
                fill="currentColor"
            />
            <path
                d="M24.3274 6.917C22.6238 6.45292 21.3652 6.25977 19.5395 6.25977C17.7122 6.25944 16.4503 6.45292 14.75 6.91538C14.6695 6.93429 14.6542 7.01649 14.6912 7.0668C15.2732 7.55038 15.517 8.19588 15.631 8.92556C16.5656 8.69681 17.4083 8.56333 18.3876 8.50818V17.3442C18.3876 17.3752 18.4094 17.3952 18.4441 17.3952H20.6335C20.6715 17.3984 20.69 17.3808 20.689 17.3462V8.5098C21.6313 8.56629 22.4179 8.69842 23.346 8.92556C23.4862 8.19981 23.7811 7.52463 24.3777 7.07137C24.431 7.00577 24.3849 6.933 24.3274 6.917Z"
                fill="currentColor"
            />
            <path
                d="M40.66 6.1664C40.497 6.14357 40.0141 6.07376 39.5881 6.08943C37.4926 6.17001 35.1227 7.41333 35.1227 9.85093V17.3673C35.1214 17.3986 35.1445 17.4172 35.1783 17.4172H37.3673C37.4053 17.4211 37.4238 17.4048 37.4231 17.3693V17.1729H37.4238V9.85093C37.4238 9.0469 38.4084 8.55023 39.2296 8.3825C39.4689 7.58069 39.9388 7.00279 40.6575 6.42359C40.7834 6.32077 40.7769 6.18309 40.66 6.1664Z"
                fill="currentColor"
            />
            <path
                d="M12.278 10.4931C12.278 10.5182 12.2686 10.5421 12.2517 10.5603C12.2355 10.5783 12.2098 10.5904 12.1822 10.5904H7.98473V9.82342C7.98473 8.77926 9.22071 8.28975 10.1975 8.28975C11.1093 8.28975 12.2793 8.77926 12.2793 9.82342L12.278 10.4931ZM10.1975 6.00806C8.03379 6.02965 5.65186 7.2873 5.65186 9.82346V13.6831C5.65186 16.2627 8.03379 17.4786 10.1975 17.5001C11.7471 17.4845 13.5123 16.8387 14.3531 15.474C14.382 15.4282 14.356 15.3686 14.3135 15.3437C13.5718 14.9169 13.1421 14.6801 12.4083 14.2647C12.3849 14.2519 12.3602 14.2519 12.3424 14.2839C11.9403 14.9541 10.9174 15.3255 10.1975 15.3255C9.22075 15.3255 7.98478 14.7283 7.98478 13.6831V12.5309H13.9852C14.305 12.5309 14.5654 12.2691 14.5654 11.947V9.82351C14.5654 7.2873 12.3193 6.02965 10.1975 6.00806Z"
                fill="currentColor"
            />
        </svg>
    );
};
