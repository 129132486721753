import React from 'react';

export const PlatformMonery: React.FC = () => {
    return (
        <svg
            width="214"
            height="36"
            viewBox="0 0 214 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'monery'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8301 13.2713V7.63397L18.6226 1.71625L18.8022 1.76947L22.7836 6.85684L23.8895 13.2713H16.3691V12.6077C16.3691 11.7526 15.6759 11.0594 14.8208 11.0594C13.9657 11.0594 13.2725 11.7526 13.2725 12.6077V13.2713H12.8301Z"
                fill="currentColor"
                fillOpacity="0.4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.1992 13.2713V7.63397L34.9917 1.71625L35.1713 1.76947L40.0375 2.43306L40.2587 13.2713H32.7382V12.6077C32.7382 11.7526 32.045 11.0594 31.1899 11.0594C30.3348 11.0594 29.6416 11.7526 29.6416 12.6077V13.2713H29.1992Z"
                fill="currentColor"
                fillOpacity="0.4"
            />
            <rect x="16.3672" width="13.2713" height="30.9665" rx="6.63567" fill="currentColor" />
            <rect x="32.7344" width="13.2713" height="30.9665" rx="6.63567" fill="currentColor" />
            <g filter="url(#filter0_i_5548_15716)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.51153 18.1745C0.90159 19.8192 0 22.0292 0 24.3307V24.3307C0 27.9955 2.97089 30.9664 6.63567 30.9664C10.3005 30.9664 13.2713 27.9955 13.2713 24.3307V7.18199L2.51153 18.1745Z"
                    fill="currentColor"
                />
            </g>
            <path
                d="M56.5781 26.9909H63.2596V14.1884C63.2596 11.7313 64.7252 9.87779 66.9236 9.87779C69.2082 9.87779 70.4582 11.5158 70.4582 13.8435V26.9909H77.1397V14.2315C77.1397 11.6882 78.6053 9.87779 80.8037 9.87779C83.1314 9.87779 84.2522 11.5158 84.2522 14.016V26.9909H90.9336V11.6882C90.9336 6.94659 87.6144 3.67053 82.6572 3.67053C79.8122 3.67053 77.6569 4.66197 76.062 6.77416H75.7603C74.3809 4.8344 72.1394 3.67053 69.1651 3.67053C66.1477 3.67053 64.1648 5.17924 63.2164 6.68795H62.8716V4.23091H56.5781V26.9909Z"
                fill="currentColor"
            />
            <path
                d="M105.982 27.5512C113.31 27.5512 118.267 22.3354 118.267 15.6109C118.267 8.88635 113.31 3.67053 106.025 3.67053C98.6543 3.67053 93.7402 8.88635 93.7402 15.6109C93.7402 22.3354 98.6974 27.5512 105.982 27.5512ZM105.982 21.5164C102.836 21.5164 100.508 19.1025 100.508 15.6109C100.508 12.1624 102.836 9.70537 105.982 9.70537C109.172 9.70537 111.5 12.1624 111.5 15.6109C111.5 19.0594 109.172 21.5164 105.982 21.5164Z"
                fill="currentColor"
            />
            <path
                d="M121.786 26.9909H128.467V14.2315C128.467 11.8176 130.019 9.87779 132.347 9.87779C134.632 9.87779 136.011 11.5589 136.011 13.8866V26.9909H142.692V11.8176C142.692 7.0759 139.589 3.67053 134.545 3.67053C131.485 3.67053 129.33 5.17924 128.424 6.73106H128.079V4.23091H121.786V26.9909Z"
                fill="currentColor"
            />
            <path
                d="M157.03 27.5512C163.194 27.5512 167.332 24.4907 168.367 19.7059H162.332C161.685 21.1284 159.918 22.4216 157.288 22.4216C154.142 22.4216 152.03 20.3525 151.814 17.4644H168.582V15.5247C168.582 9.1881 164.66 3.67053 157.159 3.67053C150.219 3.67053 145.477 8.62772 145.477 15.7402C145.477 22.594 150.435 27.5512 157.03 27.5512ZM151.943 13.0245C152.245 10.783 154.314 8.75704 157.159 8.75704C160.133 8.75704 162.116 10.6106 162.375 13.0245H151.943Z"
                fill="currentColor"
            />
            <path
                d="M172.134 26.9909H178.816V16.6454C178.816 12.7228 180.928 10.352 184.204 10.352H186.833V4.05849H184.635C181.574 4.05849 179.548 5.99825 178.772 7.67939H178.428V4.23091H172.134V26.9909Z"
                fill="currentColor"
            />
            <path
                d="M201.483 36C201.483 36 211.171 9.95361 213.294 4.23091H206.224L201.291 18.2958L196.137 4.23091H189.154L197.827 26.9909C197.135 28.9138 194.363 36 194.363 36H201.483Z"
                fill="currentColor"
            />
            <defs>
                <filter
                    id="filter0_i_5548_15716"
                    x="0"
                    y="7.18199"
                    width="13.2715"
                    height="24.3704"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.585973" />
                    <feGaussianBlur stdDeviation="0.292987" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5548_15716" />
                </filter>
            </defs>
        </svg>
    );
};
