import React from 'react';

export const PlatformAnycoin: React.FC = () => {
    return (
        <svg
            width="219"
            height="55"
            viewBox="0 0 219 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'anycoin'}
        >
            <path
                d="M27.5 0C12.375 0 0 12.375 0 27.5C0 42.6251 12.375 55 27.5 55C42.6249 55 55 42.625 55 27.5C55 12.375 42.625 0 27.5 0ZM27.5 50.7692C14.7019 50.7692 4.23075 40.298 4.23075 27.5C4.23075 14.702 14.7019 4.23079 27.5 4.23079C40.2981 4.23079 50.7692 14.7019 50.7692 27.5C50.7692 40.2981 40.2981 50.7692 27.5 50.7692Z"
                fill="currentColor"
            />
            <path
                d="M26.9907 20.7285L26.2327 25.3996C27.5534 25.6138 31.6516 26.5953 32.0812 23.9525C32.5281 21.1958 28.3114 20.9427 26.9907 20.7285Z"
                fill="currentColor"
            />
            <path
                d="M25.8502 27.7568L25.0139 32.9071C26.5997 33.1628 31.5201 34.3003 31.9902 31.3951C32.482 28.3652 27.4361 28.0145 25.8502 27.7568Z"
                fill="currentColor"
            />
            <path
                d="M27.4999 8.0957C16.783 8.0957 8.09521 16.7835 8.09521 27.5004C8.09521 38.2174 16.783 46.9052 27.4999 46.9052C38.2167 46.9052 46.9046 38.2173 46.9046 27.5004C46.9046 16.7836 38.2168 8.0957 27.4999 8.0957ZM36.538 32.6065C35.5969 36.2445 32.6833 36.7888 28.7135 36.3763L28.0832 40.2565L25.7437 39.8772L26.3659 36.0489C25.7596 35.9511 25.1394 35.8452 24.4999 35.7279L23.8759 39.574L21.539 39.1945L22.1687 35.3071C21.622 35.2148 21.0661 35.1147 20.4997 35.0221L17.4551 34.5282L18.3733 31.8097C18.3733 31.8097 20.0995 32.1174 20.0739 32.0861C20.7361 32.1924 20.9886 31.7453 21.0785 31.4538L22.7871 20.9249C22.7745 20.4356 22.5553 19.8354 21.6077 19.6814C21.6416 19.6542 19.9086 19.4068 19.9086 19.4068L20.3134 16.9081L23.54 17.4328L23.5384 17.4447C24.0234 17.5231 24.5224 17.5944 25.0306 17.6662L25.6549 13.824L27.9931 14.2035L27.3815 17.9704C28.0081 18.0592 28.6388 18.1491 29.2538 18.2488L29.8608 14.5066L32.2004 14.8859L31.5769 18.7294C34.5538 19.484 36.795 20.8009 36.6118 23.6151C36.4811 25.6748 35.4356 26.7541 33.9672 27.2247C36.1129 28.1231 37.2966 29.6772 36.538 32.6065Z"
                fill="currentColor"
            />
            <path
                d="M78.4033 30.1806L75.5265 21.9356L72.6494 30.1806H78.4033ZM73.4371 16.2031H77.9589L86.3592 38.7043H81.5803L79.775 33.9415H71.2514L69.4725 38.7043H65.0366L73.4371 16.2031Z"
                fill="currentColor"
            />
            <path
                d="M90.6973 16.2031H94.9939L105.259 31.177V16.2031H109.77V38.7043H105.473L95.2082 23.7302V38.7043H90.6973V16.2031Z"
                fill="currentColor"
            />
            <path
                d="M122.615 30.0681L114.054 16.2031H119.347L124.951 25.4768L130.212 16.2031H135.055L127.137 30.0681V38.7043H122.615V30.0681Z"
                fill="currentColor"
            />
            <path
                d="M137.144 27.6145V26.9716C137.144 19.1551 141.612 15.8281 147.162 15.8281C152.176 15.8281 156.157 18.2175 156.537 23.4893H151.823C151.319 20.8588 149.6 19.6532 147.162 19.6532C144.435 19.6532 141.858 21.4695 141.858 26.9716V27.6145C141.858 33.4112 144.623 35.254 147.162 35.254C149.755 35.254 151.464 33.8826 151.823 31.4182H156.537C156.077 36.9632 152.23 39.0793 147.162 39.0793C141.424 39.0793 137.144 35.8006 137.144 27.6145Z"
                fill="currentColor"
            />
            <path
                d="M177.109 27.6145V26.9716C177.109 21.5927 174.344 19.6532 171.323 19.6532C168.302 19.6532 165.537 21.5927 165.537 26.9716V27.6145C165.537 33.3468 168.301 35.254 171.323 35.254C174.345 35.254 177.109 33.3468 177.109 27.6145ZM160.822 27.6145V26.9716C160.822 19.1497 165.778 15.8281 171.323 15.8281C176.868 15.8281 181.823 19.1497 181.823 26.9716V27.6145C181.823 35.7844 176.868 39.0793 171.323 39.0793C165.778 39.0793 160.822 35.7844 160.822 27.6145Z"
                fill="currentColor"
            />
            <path d="M192.29 16.2031H187.769V38.7043H192.29V16.2031Z" fill="currentColor" />
            <path
                d="M199.254 16.2031H203.551L213.816 31.177V16.2031H218.327V38.7043H214.03L203.765 23.7302V38.7043H199.254V16.2031Z"
                fill="currentColor"
            />
        </svg>
    );
};
