import React from 'react';

export const PlatformDegiro: React.FC = () => {
    return (
        <svg
            width="85"
            height="12"
            viewBox="0 0 85 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={'degiro'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9003 11.765C29.7853 11.765 32.7286 10.4709 32.7286 6.00031C32.7286 1.52972 29.6676 0.235596 25.9003 0.235596H22.133V11.765H25.9003ZM24.8407 9.41208V2.47089V2.35325H26.018C28.1372 2.35325 29.9031 2.82383 29.9031 5.88267C29.9031 8.94148 28.0194 9.41208 26.018 9.41208H24.8407Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.971 0.235596V2.58854H36.8492V4.58854H42.6178V7.05911H36.8492V9.41208H42.971V11.765H34.0237V0.235596H42.971Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.7425 3.76471C52.5071 2.94118 51.683 2.23529 50.1525 2.23529C48.5043 2.23529 47.2093 3.52941 47.2093 5.88237C47.2093 8.35293 48.3865 9.64705 50.2702 9.64705C51.5652 9.64705 52.8603 9.1765 52.8603 7.64704V7.52941H50.0348V5.29412H55.5682V11.7647H53.4489L53.3311 10.4706C52.6248 11.5294 51.4475 12 49.7993 12C46.3852 12 44.2661 9.64705 44.2661 6C44.2661 2.35294 46.6206 0 50.1525 0C52.7425 0 54.8615 1.29412 55.3323 3.76471H52.7425Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.5695 11.765H60.277V0.235596H57.5695V11.765Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.9863 11.765V7.41208H66.9876L69.4597 11.765H72.6385L69.8134 6.94147C71.108 6.47092 72.0501 5.52971 72.0501 3.76501C72.0501 0.941477 69.8134 0.235596 66.7523 0.235596H62.2783V11.765H64.9863ZM66.7523 5.05913H64.9863V2.47089H66.7523C68.0474 2.47089 68.989 2.70619 68.989 3.76501C68.989 4.82384 68.0474 5.05913 66.7523 5.05913Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.9961 0C75.4642 0 72.9916 2.35294 72.9916 6C72.9916 9.64705 75.4642 12 78.9961 12C82.5279 12 85 9.64705 85 6C85 2.35294 82.5279 0 78.9961 0ZM79.1138 2.23529C80.762 2.23529 82.1748 3.52941 82.1748 6C82.1748 8.47056 80.762 9.76469 79.1138 9.76469C77.4655 9.76469 76.0527 8.47056 76.0527 6C76.0527 3.52941 77.4655 2.23529 79.1138 2.23529Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4.82383H19.3075V0.235596H0V4.82383Z"
                fill="currentColor"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 11.7647H19.3075V7.05884H0V11.7647Z" fill="currentColor" />
        </svg>
    );
};
